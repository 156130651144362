<template>
  <div id="student-new-modal">
    <!-- WEBCAM -->
    <webcam-photo ref="webcam" @snapShot="onValueChanged" />
    <vs-popup class="popup90" :title="title" :active.sync="localShow">

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="flex items-start flex-col lg:flex-row">
            <div @mouseover="mouseOver = true" @mouseleave="mouseOver = false" @click="loadCamera" id="photo-loading" class="cursor-pointer p-2 vs-con-loading__container mt-6" style="position: absolute; width:200px; height:200px">
              <feather-icon v-if="mouseOver" svgClasses="h-16 w-16 stroke-current text-success" icon="CameraIcon" style="margin-left: 55px; margin-top: 54px" />
            </div>
            <img :src="student.photo || noUserPhoto" class="mt-6 mr-8 rounded h-56 w-56" @load="closeLoading" />
            <div class="w-full">
              <div class="vx-row">
                <div class="vx-col md:w-1/2 w-full mt-0">
                  <vs-input class="w-full mt-0" label="Nome" v-model="student.name" data-vv-as="Nome" v-validate.initial="'required|alpha_spaces'" name="name" />
                  <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first('name') }}</span>
                </div>
                <div class="vx-col md:w-1/4 w-full mt-0">
                  <label class="vs-input--label">Situação</label>
                  <v-select v-model="student.status" :reduce="option => option.value" :clearable="false" :options="statusOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                    <template #no-options="{}">
                      Desculpe, nenhum resultado encontrado.
                    </template>
                  </v-select>
                </div>
                <div class="vx-col md:w-1/4 w-full mt-0">
                  <label class="vs-input--label">Tipo de Pessoa</label>
                  <v-select v-model="student.type" :reduce="option => option.value" :clearable="false" :options="typeOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                    <template #no-options="{}">
                      Desculpe, nenhum resultado encontrado.
                    </template>
                  </v-select>
                </div>
              </div>

              <div class="vx-row">
                <div class="vx-col md:w-1/4 w-full mt-2">
                  <vs-input class="w-full" label="CPF" @keyup="searchStudentByCpf" v-model="student.cpf" v-mask="['###.###.###-##','##.###.###/####-##']" data-vv-as="CPF" v-validate="'cpf'" name="cpf" />
                  <span class="text-danger text-sm" v-show="errors.has('cpf')">{{ errors.first('cpf') }}</span>
                  <span class="text-danger text-sm" v-if="doubleCpf">Este CPF já está cadastrado.</span>
                </div>

                <div class="vx-col md:w-1/4 w-full mt-2">
                  <vs-input class="w-full" label="RG/IE" v-model="student.rg" />
                </div>

                <div class="vx-col md:w-1/4 w-full mt-2">
                  <vs-input type="date" class="w-full" label="Data de Nascimento" v-model="student.date_of_birth" v-validate="'date_format:yyyy-MM-dd'" data-vv-as="Data de Nascimento" name="date_of_birth" />
                  <span class="text-danger text-sm" v-show="errors.has('date_of_birth')">Formato incorreto</span>
                </div>

                <div class="vx-col md:w-1/4 w-full mt-2">
                  <div>
                    <label class="text-sm">Sexo</label>
                    <div class="mt-2">
                      <vs-radio v-model="student.gender" vs-value="male" class="mr-4">Masc.</vs-radio>
                      <vs-radio v-model="student.gender" vs-value="feminine" class="mr-4">Fem.</vs-radio>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex items-end mt-2">
                <feather-icon icon="MapPinIcon" class="mr-2" svgClasses="w-5 h-5" />
                <span class="leading-none font-medium">Endereço</span>
              </div>
              <hr>

              <div class="vx-row">
                <div class="vx-col md:w-1/4 w-full mt-2">
                  <vs-input class="w-full" label="CEP" placeholder="CEP" v-model="student.address.zip_code" v-mask="['#####-###']" @keyup="searchZipCode" />
                </div>

                <div class="vx-col md:w-1/4 w-full mt-2">
                  <label class="vs-input--label">Logradouro</label>
                  <v-select v-model="student.address.logradouro" :reduce="option => option.value" clearable :options="logradouroOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                    <template #no-options="{}">
                      Desculpe, nenhum resultado encontrado.
                    </template>
                  </v-select>
                </div>

                <div class="vx-col md:w-1/4 w-full mt-2">
                  <vs-input class="w-full" label="Endereço" v-model="student.address.street" />
                </div>

                <div class="vx-col md:w-1/4 w-full mt-2">
                  <vs-input class="w-full" label="Número" v-model="student.address.number" type="number" placeholder="S/N" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full">
          <vs-input class="w-full mt-2" label="Complemento" v-model="student.address.complement" />
        </div>
        <div class="vx-col md:w-1/5 w-full">
          <vs-input class="w-full mt-2" label="Bairro" v-model="student.address.neighborhood" />
        </div>
        <div class="vx-col md:w-1/4_ w-full mt-2">
          <label class="vs-input--label">Cidade</label>
          <v-select v-model="student.address.city_id" :reduce="option => option.value" :placeholder="cityOptions.length ? 'Selecione' : 'Selecione um estado'" :options="cityOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-2">
          <label class="vs-input--label">Estado</label>
          <v-select v-model="student.address.state_id" :reduce="option => option.value" @input="fillCity(student.address.state_id), student.address.city_id = null" :options="states" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-2">
          <label class="vs-input--label">País</label>
          <v-select v-model="student.address.country" :reduce="option => option.value" :clearable="false" :options="countryOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <div class="flex items-end mt-2">
        <feather-icon icon="PhoneIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Contato</span>
      </div>
      <hr>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full" v-for="(phone,i) in student.phones" :key="i">
          <vs-input class="w-full mt-2" v-mask="['(##)#####-####','(##)####-####']" :label="phone.type === 'phone' ? 'Telefone' : phone.type === 'mobile' ? 'Celular' : phone.type === 'other' ? 'Outro' : ''" v-model="phone.phone" />
        </div>
        <div class="vx-col md:w-2/5 w-full">
          <vs-input class="w-full mt-2" label="E-mail" data-vv-as="E-mail" v-model="student.email" type="email" v-validate="'email'" name="email" />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <div class="mt-2">
            <label class="text-sm">Aceita Comunicados</label>
            <div class="mt-2">
              <div class="flex flex-wrap mt-1">
              <vs-checkbox v-model="student.type_of_communication" vs-value="email" class="mr-4 mb-2">E-mail</vs-checkbox>
              <vs-checkbox v-model="student.type_of_communication" vs-value="sms" class="mr-4 mb-2">SMS</vs-checkbox>
              <vs-checkbox v-model="student.type_of_communication" vs-value="whatsapp" class=" mb-2">Whatsapp</vs-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/3_ w-full">
          <vs-input class="w-full mt-2" label="Facebook" icon-pack="feather" icon="icon-facebook" icon-no-border v-model="student.facebook" />
        </div>
        <div class="vx-col md:w-1/3_ w-full">
          <vs-input class="w-full mt-2" label="Instagram" icon-pack="feather" icon="icon-instagram" icon-no-border v-model="student.instagram" />
        </div>
        <div class="vx-col md:w-1/3_ w-full">
          <vs-input class="w-full mt-2" label="Outra Mídia Social" icon-pack="feather" icon="icon-share-2" icon-no-border v-model="student.other_social_media" />
        </div>
      </div>

      <div class="flex items-end mt-2">
        <feather-icon icon="InfoIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Outros</span>
      </div>
      <hr>

      <div class="vx-row">
        <div class="vx-col md:w-2/5 w-full mt-2">
          <label class="vs-input--label">Serviço Contratado</label>
          <v-select v-model="student.intended_services" clearable :reduce="option => option.value"
            @input="changeLastService" :options="servicePackages" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Cat. Desejada</label>
          <v-select v-model="student.intended_category_cnh" :options="categoryCNHOptions" :reduce="option => option.value" placeholder="Selecione"
            :clearable="true" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <vs-input type="date" class="w-full" label="Emissão de LADV" v-model="student.ladv_emission" v-validate="'date_format:yyyy-MM-dd'" data-vv-as="Emissão de LADV" name="ladv_emission" />
          <span class="text-danger text-sm" v-show="errors.has('ladv_emission')">Formato incorreto</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <vs-input class="w-full" label="RENACH" v-model="student.renach_form_number" />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full mt-2">
          <vs-input type="date" class="w-full" label="Abertura RENACH" v-model="student.opening_date_renach_form_number" v-validate="'date_format:yyyy-MM-dd'" data-vv-as="Data Abertura RENACH" name="opening_date_renach_form_number" />
          <span class="text-danger text-sm" v-show="errors.has('opening_date_renach_form_number')">Formato incorreto</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">CNH Impressa|Digital</label>
          <v-select v-model="student.cnh_printed_digital" :clearable="true" :reduce="option => option.value" :options="typeCNHOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-2/5 w-full mt-2">
          <vs-input class="w-full" label="Campo Extra" v-model="student.extra_field" />
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Grupos</label>
          <v-select v-model="student.groups" :reduce="status => status.value" clearable multiple :options="groups" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="save" :disabled="!validateForm || doubleCpf">Salvar Alterações</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="secondary" @click="cancel">Cancelar</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import moment from 'moment'
import vSelect from 'vue-select'
import WebcamPhoto from '@/components/webcam-photo/WebcamNewPhoto'
import moduleStates from '@/store/state/moduleStates.js'
import moduleGroups from '@/store/groups/moduleGroups.js'
import moduleServicePackage from '@/store/service-package/moduleServicePackage.js'

export default {
  name: 'new-student-modal',
  components: {
    vSelect,
    WebcamPhoto
  },
  props: {
    show: {
      Boolean, default: false
    },
    title: {
      String, default: 'Diálogo'
    },
    actionButtonText: {
      String, default: 'Deletar'
    },
    actionButtonColor: {
      String, default: 'danger'
    }
  },

  data () {
    return {
      localShow: false,
      mouseOver: null,
      mouseLeave: null,
      noUserPhoto: require('@/assets/images/user/material-design-user-icon-29.jpg'),
      doubleCpf: false,
      student: {
        photo: null,
        name: '',
        status: 1,
        type: 'FISICA',
        cpf: '',
        rg: '',
        date_of_birth: '',
        gender: null,
        email: '',
        type_of_communication: [],
        facebook: '',
        instagram: '',
        other_social_media: '',
        intended_services: '',
        ladv_emission: '',
        cnh_printed_digital: '',
        renach_form_number: '',
        opening_date_renach_form_number: '',
        date_expiration_renach_form_number: '',
        extra_field: '',
        groups: [],

        phones: [
          {type: 'mobile', phone: null},
          {type: 'phone', phone: null},
          {type: 'other', phone: null}
        ],
        address: {
          logradouro: null,
          street: null,
          number: null,
          complement: null,
          neighborhood: null,
          zip_code: null,
          observation: null,
          type_address: 'res',
          state_id: null,
          city_id: null,
          country: 1 // Brasil
        }
      },

      statusOptions: [
        { label: 'Ativo',  value: 1 },
        { label: 'Inativo',  value: 0 }
      ],

      typeOptions: [
        { label: 'Física',  value: 'FISICA' },
        { label: 'Jurídica',  value: 'JURIDICA' }
      ],

      countryOptions: [{ label: 'Brasil', value: 1 }],

      typeCNHOptions: [
        { label: 'Impressa', value: 1 },
        { label: 'Digital',  value: 2 },
        { label: 'Imp. e Digital',  value: 3 }
      ],

      categoryCNHOptions: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'AB', value: 'AB' },
        { label: 'C', value: 'C' },
        { label: 'D', value: 'D' },
        { label: 'E', value: 'E' },
        { label: 'AC', value: 'AC' },
        { label: 'AD', value: 'AD' },
        { label: 'AE', value: 'AE' },
        { label: 'ACC', value: 'ACC' }
      ],

      logradouroOptions: [
        { label: 'Rua', value: 'Rua' },
        { label: 'Avenida', value: 'Avenida' },
        { label: 'Rodovia', value: 'Rodovia' },
        { label: 'Estrada', value: 'Estrada' },
        { label: 'Lote', value: 'Lote' },
        { label: 'Servidão', value: 'Servidão' },
        { label: 'Quadra', value: 'Quadra' },
        { label: 'Travessa', value: 'Travessa' },
        { label: 'Linha', value: 'Linha' }
      ],

      cityOptions:[]
    }
  },

  watch: {
    show (val) {
      this.localShow = val
    },
    localShow (val) {
      if (!val) {
        this.student = {
          photo: null,
          name: '',
          status: 1,
          type: 'FISICA',
          cpf: '',
          rg: '',
          date_of_birth: '',
          gender: null,
          email: '',
          type_of_communication: [],
          facebook: '',
          instagram: '',
          other_social_media: '',
          intended_services: '',
          ladv_emission: '',
          cnh_printed_digital: '',
          renach_form_number: '',
          opening_date_renach_form_number: '',
          date_expiration_renach_form_number: '',
          extra_field: '',
          groups: [],

          phones: [
            {type: 'mobile', phone: null},
            {type: 'phone', phone: null},
            {type: 'other', phone: null}
          ],

          address: {
            logradouro: null,
            street: null,
            number: null,
            complement: null,
            neighborhood: null,
            zip_code: null,
            observation: null,
            type_address: 'res',
            state_id: null,
            city_id: null,
            country: 1 // Brasil
          }
        }
        this.$emit('cancel')
      } else {
        //ESTADO DEFAULT SC
        this.student.address.state_id = 24
        this.fillCity(this.student.address.state_id)
      }
    },
    student: {
      deep: true,
      handler (data) {
        if (this.user.company.configuration) {
          if (this.user.company.configuration.general && this.user.company.configuration.general.uppercase_forms) {
            // SE CONFIGURADO PARA USAR MAIÚSUCAS NOS FORMS
            // MÉTODO VARRE TODO O OBJETO COLOCANDO MAIÚSCULOS OS CAMPOS STRING DO IF DENTRO DO MÉTODO upper
            this.upper(data)
          }
        }
      }
    }
  },

  computed: {
    states () {
      return this.$store.getters['states/getStates']
    },
    servicePackages () {
      return this.$store.getters['servicePackage/forSelect']
    },
    // serviceHistories () {
    //   return this.$store.state.serviceHistory.serviceHistories
    // },
    groups () {
      return this.$store.getters['groups/getGroups']
    },
    validateForm () {
      return !this.errors.any()
    },
    user () {
      return this.$store.state.AppActiveUser
    }
  },

  methods: {
    action () {
      this.$emit('action')
    },
    cancel () {
      this.$emit('cancel')
    },
    upper (obj) {
      for (const prop in obj) {
        if (typeof obj[prop] === 'string') {
          //LISTAR OS CAMPOS DO OBJETO QUE DEVEM SER ALTERADOS
          if (prop === 'occupation' || prop === 'workplace' || prop === 'mothers_name' || prop === 'fathers_name' || prop === 'responsible_withdrawal'
              || prop === 'observation' || prop === 'name' || prop === 'street' || prop === 'complement' || prop === 'neighborhood' || prop === 'extra_field') {
            obj[prop] = obj[prop].toUpperCase()
          }
        }
        if (typeof obj[prop] === 'object' && prop !== 'phones' && prop !== 'email' && prop !== 'emails') {
          this.upper(obj[prop])
        }
      }
      return obj
    },
    onValueChanged (value) {
      this.student.photo = value.base_img
      // this.student.photo.url = newValue
    },
    loadCamera () {
      this.$refs.webcam.loadCamera()
    },
    openLoading () {
      this.$vs.loading({
        container: '#photo-loading',
        scale: 0.6
      })
    },
    closeLoading () {
      this.$vs.loading.close('#photo-loading > .con-vs-loading')
    },
    changeLastService () {
      const index = this.servicePackages.findIndex(e => e.value === this.student.intended_services)
      if (index >= 0) this.student.intended_category_cnh = this.servicePackages[index].data.category_cnh
    },
    searchStudentByCpf () {
      this.doubleCpf = false
      const identification = this.student.cpf
      if ((identification.length === 14 || identification.length === 18) && !this.errors.has('cpf')) {
        const cpf_cnpj = identification.replace(/\D/g, '')
        this.$http.get(`${process.env.VUE_APP_API_URL}/student/getByCpfCnpj/${cpf_cnpj}/STUDENT`)
          .then((response) => {
            if (response.data.id) {
              this.doubleCpf = true
            }
          })
          .catch((error)   => { console.log(error) })
      }
    },

    save () {
      if (!this.validateForm) return

      // Loading
      this.$vs.loading()
      if (!this.student.gender) this.student.gender = 'undefined'
      // VENCIMMENTO DO RENACH AUTOMÁTICO PARA 1 ANO // SEGUNDO REGRAS EM 13/04/2021
      if (this.student.opening_date_renach_form_number && moment(this.student.opening_date_renach_form_number).year() > 1000) {
        this.student.date_expiration_renach_form_number = moment(this.student.opening_date_renach_form_number).add(1, 'year').format('YYYY-MM-DD')
      }

      this.$store.dispatch('studentManagement/store', this.student)
        .then((resp) => {
          this.localShow = false
          this.$vs.loading.close()

          this.$nextTick(() => {
            this.$router.push(`/student/student-edit/${resp.data.id}`)
          })

          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Salvos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(error => {
          this.$vs.loading.close()
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: 'Algo saiu errado. Por favor chame o suporte.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        })
    },
    async searchZipCode ($event) {
      const zip_code = this.student.address.zip_code.trim().replace(/[^0-9]/g, '')

      if (zip_code.length === 8 && ($event.keyCode < 37 || $event.keyCode > 40)) { // $event.keyCode evita chamadas de API ao teclar setas

        this.$http._noHeaders = true //enviando um get sem Autorization no header
        const resp = await this.$http.get(`https://viacep.com.br/ws/${zip_code}/json`)
        if (resp.data.erro) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: 'Este CEP não existe ou não foi encontrado.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }

        this.student.address.neighborhood = resp.data.bairro
        this.student.address.complement = resp.data.complemento

        //abaixo testo a primeira palavra da rua (logradouro), se existir removo da rua e seleciono o logradouro
        const rua = /Rua /i
        const avenida = /Avenida /i
        const rodovia = /Rodovia /i
        const estrada = /Estrada /i
        const lote = /Lote /i
        const servidao = /Servidão /i
        const quadra = /Quadra /i
        const travessa = /Travessa /i
        const linha = /Linha /i

        if (rua.test(resp.data.logradouro)) {
          this.student.address.logradouro = 'Rua'
          const regEx = new RegExp('Rua ', 'ig')
          this.student.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (avenida.test(resp.data.logradouro)) {
          this.student.address.logradouro = 'Avenida'
          const regEx = new RegExp('Avenida ', 'ig')
          this.student.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (rodovia.test(resp.data.logradouro)) {
          this.student.address.logradouro = 'Rodovia'
          const regEx = new RegExp('Rodovia ', 'ig')
          this.student.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (estrada.test(resp.data.logradouro)) {
          this.student.address.logradouro = 'Estrada'
          const regEx = new RegExp('Estrada ', 'ig')
          this.student.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (lote.test(resp.data.logradouro)) {
          this.student.address.logradouro = 'Lote'
          const regEx = new RegExp('Lote ', 'ig')
          this.student.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (servidao.test(resp.data.logradouro)) {
          this.student.address.logradouro = 'Servidão'
          const regEx = new RegExp('Servidão ', 'ig')
          this.student.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (quadra.test(resp.data.logradouro)) {
          this.student.address.logradouro = 'Quadra'
          const regEx = new RegExp('Quadra ', 'ig')
          this.student.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (travessa.test(resp.data.logradouro)) {
          this.student.address.logradouro = 'Travessa'
          const regEx = new RegExp('Travessa ', 'ig')
          this.student.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (linha.test(resp.data.logradouro)) {
          this.student.address.logradouro = 'Linha'
          const regEx = new RegExp('Linha ', 'ig')
          this.student.address.street = resp.data.logradouro.replace(regEx, '')
        } else {
          this.student.address.street = resp.data.logradouro
        }

        const state = this.states.find(o => o.initials === resp.data.uf)
        this.student.address.state_id = state.value
        await this.fillCity(state.value, resp.data.localidade)
      }
    },

    async fillCity (stateId, city = null) {
      this.cityOptions = []
      try {
        if (stateId) {
          const resp = await this.$http.get(`${process.env.VUE_APP_API_URL}/cities/${stateId}`)
          // this.cityOptions.push({ value: null, label: 'Selecione' })

          for (const k in resp.data) {
            this.cityOptions.push({ value: resp.data[k].id, label: resp.data[k].city })
          }
          if (city) {
            //procura pelo id, se não encontrar peocura pelo nome (motivo: da api recebo ID e do CEP recebo nome)
            let ct = this.cityOptions.find(x => x.value === city)
            if (!ct) {
              ct = this.cityOptions.find(x => x.label === city)
            }
            this.student.address.city_id = ct.value
          }
        }
      } catch (error) { console.error(error) }
    }
  },
  created () {
    if (!moduleStates.isRegistered) {
      this.$store.registerModule('states', moduleStates)
      moduleStates.isRegistered = true
    }
    this.$store.dispatch('states/fetchStates')

    if (!moduleServicePackage.isRegistered) {
      // this.$store.registerModule('servicePackage', moduleServicePackage)
      moduleServicePackage.isRegistered = true
    }
    this.$store.dispatch('servicePackage/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleGroups.isRegistered) {
      this.$store.registerModule('groups', moduleGroups)
      moduleGroups.isRegistered = true
    }
    this.$store.dispatch('groups/fetchGroups')

  }
}
</script>

<style>
  hr {
    margin-top: 2px;
    border: none;
    height: 1px;
    /* Set the hr color */
    color: rgb(204, 204, 204); /* old IE */
    background-color: rgb(204, 204, 204); /* Modern Browsers */
  }

  .popup90 .vs-popup {
    width: 90% !important;
  }
</style>
