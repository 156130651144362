var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "student-new-modal" } },
    [
      _c("webcam-photo", {
        ref: "webcam",
        on: { snapShot: _vm.onValueChanged },
      }),
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: { title: _vm.title, active: _vm.localShow },
          on: {
            "update:active": function ($event) {
              _vm.localShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "flex items-start flex-col lg:flex-row" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "cursor-pointer p-2 vs-con-loading__container mt-6",
                      staticStyle: {
                        position: "absolute",
                        width: "200px",
                        height: "200px",
                      },
                      attrs: { id: "photo-loading" },
                      on: {
                        mouseover: function ($event) {
                          _vm.mouseOver = true
                        },
                        mouseleave: function ($event) {
                          _vm.mouseOver = false
                        },
                        click: _vm.loadCamera,
                      },
                    },
                    [
                      _vm.mouseOver
                        ? _c("feather-icon", {
                            staticStyle: {
                              "margin-left": "55px",
                              "margin-top": "54px",
                            },
                            attrs: {
                              svgClasses:
                                "h-16 w-16 stroke-current text-success",
                              icon: "CameraIcon",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("img", {
                    staticClass: "mt-6 mr-8 rounded h-56 w-56",
                    attrs: { src: _vm.student.photo || _vm.noUserPhoto },
                    on: { load: _vm.closeLoading },
                  }),
                  _c("div", { staticClass: "w-full" }, [
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-0" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.initial",
                                value: "required|alpha_spaces",
                                expression: "'required|alpha_spaces'",
                                modifiers: { initial: true },
                              },
                            ],
                            staticClass: "w-full mt-0",
                            attrs: {
                              label: "Nome",
                              "data-vv-as": "Nome",
                              name: "name",
                            },
                            model: {
                              value: _vm.student.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.student, "name", $$v)
                              },
                              expression: "student.name",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("name"),
                                  expression: "errors.has('name')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v(_vm._s(_vm.errors.first("name")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("Situação"),
                          ]),
                          _c("v-select", {
                            attrs: {
                              reduce: (option) => option.value,
                              clearable: false,
                              options: _vm.statusOptions,
                              placeholder: "Selecione",
                              dir: _vm.$vs.rtl ? "rtl" : "ltr",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "no-options",
                                fn: function ({}) {
                                  return [
                                    _vm._v(
                                      "\n                    Desculpe, nenhum resultado encontrado.\n                  "
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.student.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.student, "status", $$v)
                              },
                              expression: "student.status",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("Tipo de Pessoa"),
                          ]),
                          _c("v-select", {
                            attrs: {
                              reduce: (option) => option.value,
                              clearable: false,
                              options: _vm.typeOptions,
                              placeholder: "Selecione",
                              dir: _vm.$vs.rtl ? "rtl" : "ltr",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "no-options",
                                fn: function ({}) {
                                  return [
                                    _vm._v(
                                      "\n                    Desculpe, nenhum resultado encontrado.\n                  "
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.student.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.student, "type", $$v)
                              },
                              expression: "student.type",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["###.###.###-##", "##.###.###/####-##"],
                                expression:
                                  "['###.###.###-##','##.###.###/####-##']",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "cpf",
                                expression: "'cpf'",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              label: "CPF",
                              "data-vv-as": "CPF",
                              name: "cpf",
                            },
                            on: { keyup: _vm.searchStudentByCpf },
                            model: {
                              value: _vm.student.cpf,
                              callback: function ($$v) {
                                _vm.$set(_vm.student, "cpf", $$v)
                              },
                              expression: "student.cpf",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("cpf"),
                                  expression: "errors.has('cpf')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v(_vm._s(_vm.errors.first("cpf")))]
                          ),
                          _vm.doubleCpf
                            ? _c(
                                "span",
                                { staticClass: "text-danger text-sm" },
                                [_vm._v("Este CPF já está cadastrado.")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: { label: "RG/IE" },
                            model: {
                              value: _vm.student.rg,
                              callback: function ($$v) {
                                _vm.$set(_vm.student, "rg", $$v)
                              },
                              expression: "student.rg",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "date_format:yyyy-MM-dd",
                                expression: "'date_format:yyyy-MM-dd'",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              type: "date",
                              label: "Data de Nascimento",
                              "data-vv-as": "Data de Nascimento",
                              name: "date_of_birth",
                            },
                            model: {
                              value: _vm.student.date_of_birth,
                              callback: function ($$v) {
                                _vm.$set(_vm.student, "date_of_birth", $$v)
                              },
                              expression: "student.date_of_birth",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("date_of_birth"),
                                  expression: "errors.has('date_of_birth')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v("Formato incorreto")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("div", [
                            _c("label", { staticClass: "text-sm" }, [
                              _vm._v("Sexo"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "mt-2" },
                              [
                                _c(
                                  "vs-radio",
                                  {
                                    staticClass: "mr-4",
                                    attrs: { "vs-value": "male" },
                                    model: {
                                      value: _vm.student.gender,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.student, "gender", $$v)
                                      },
                                      expression: "student.gender",
                                    },
                                  },
                                  [_vm._v("Masc.")]
                                ),
                                _c(
                                  "vs-radio",
                                  {
                                    staticClass: "mr-4",
                                    attrs: { "vs-value": "feminine" },
                                    model: {
                                      value: _vm.student.gender,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.student, "gender", $$v)
                                      },
                                      expression: "student.gender",
                                    },
                                  },
                                  [_vm._v("Fem.")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex items-end mt-2" },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-2",
                          attrs: { icon: "MapPinIcon", svgClasses: "w-5 h-5" },
                        }),
                        _c(
                          "span",
                          { staticClass: "leading-none font-medium" },
                          [_vm._v("Endereço")]
                        ),
                      ],
                      1
                    ),
                    _c("hr"),
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["#####-###"],
                                expression: "['#####-###']",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: { label: "CEP", placeholder: "CEP" },
                            on: { keyup: _vm.searchZipCode },
                            model: {
                              value: _vm.student.address.zip_code,
                              callback: function ($$v) {
                                _vm.$set(_vm.student.address, "zip_code", $$v)
                              },
                              expression: "student.address.zip_code",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("Logradouro"),
                          ]),
                          _c("v-select", {
                            attrs: {
                              reduce: (option) => option.value,
                              clearable: "",
                              options: _vm.logradouroOptions,
                              placeholder: "Selecione",
                              dir: _vm.$vs.rtl ? "rtl" : "ltr",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "no-options",
                                fn: function ({}) {
                                  return [
                                    _vm._v(
                                      "\n                    Desculpe, nenhum resultado encontrado.\n                  "
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.student.address.logradouro,
                              callback: function ($$v) {
                                _vm.$set(_vm.student.address, "logradouro", $$v)
                              },
                              expression: "student.address.logradouro",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: { label: "Endereço" },
                            model: {
                              value: _vm.student.address.street,
                              callback: function ($$v) {
                                _vm.$set(_vm.student.address, "street", $$v)
                              },
                              expression: "student.address.street",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              label: "Número",
                              type: "number",
                              placeholder: "S/N",
                            },
                            model: {
                              value: _vm.student.address.number,
                              callback: function ($$v) {
                                _vm.$set(_vm.student.address, "number", $$v)
                              },
                              expression: "student.address.number",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: { label: "Complemento" },
                  model: {
                    value: _vm.student.address.complement,
                    callback: function ($$v) {
                      _vm.$set(_vm.student.address, "complement", $$v)
                    },
                    expression: "student.address.complement",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: { label: "Bairro" },
                  model: {
                    value: _vm.student.address.neighborhood,
                    callback: function ($$v) {
                      _vm.$set(_vm.student.address, "neighborhood", $$v)
                    },
                    expression: "student.address.neighborhood",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4_ w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Cidade"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    placeholder: _vm.cityOptions.length
                      ? "Selecione"
                      : "Selecione um estado",
                    options: _vm.cityOptions,
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.student.address.city_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.student.address, "city_id", $$v)
                    },
                    expression: "student.address.city_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Estado"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    options: _vm.states,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  on: {
                    input: function ($event) {
                      _vm.fillCity(_vm.student.address.state_id),
                        (_vm.student.address.city_id = null)
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.student.address.state_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.student.address, "state_id", $$v)
                    },
                    expression: "student.address.state_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("País"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: _vm.countryOptions,
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.student.address.country,
                    callback: function ($$v) {
                      _vm.$set(_vm.student.address, "country", $$v)
                    },
                    expression: "student.address.country",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "PhoneIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Contato"),
              ]),
            ],
            1
          ),
          _c("hr"),
          _c(
            "div",
            { staticClass: "vx-row" },
            [
              _vm._l(_vm.student.phones, function (phone, i) {
                return _c(
                  "div",
                  { key: i, staticClass: "vx-col md:w-1/5 w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["(##)#####-####", "(##)####-####"],
                          expression: "['(##)#####-####','(##)####-####']",
                        },
                      ],
                      staticClass: "w-full mt-2",
                      attrs: {
                        label:
                          phone.type === "phone"
                            ? "Telefone"
                            : phone.type === "mobile"
                            ? "Celular"
                            : phone.type === "other"
                            ? "Outro"
                            : "",
                      },
                      model: {
                        value: phone.phone,
                        callback: function ($$v) {
                          _vm.$set(phone, "phone", $$v)
                        },
                        expression: "phone.phone",
                      },
                    }),
                  ],
                  1
                )
              }),
              _c(
                "div",
                { staticClass: "vx-col md:w-2/5 w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "email",
                        expression: "'email'",
                      },
                    ],
                    staticClass: "w-full mt-2",
                    attrs: {
                      label: "E-mail",
                      "data-vv-as": "E-mail",
                      type: "email",
                      name: "email",
                    },
                    model: {
                      value: _vm.student.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.student, "email", $$v)
                      },
                      expression: "student.email",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("email"),
                          expression: "errors.has('email')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("email")))]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
              _c("div", { staticClass: "mt-2" }, [
                _c("label", { staticClass: "text-sm" }, [
                  _vm._v("Aceita Comunicados"),
                ]),
                _c("div", { staticClass: "mt-2" }, [
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap mt-1" },
                    [
                      _c(
                        "vs-checkbox",
                        {
                          staticClass: "mr-4 mb-2",
                          attrs: { "vs-value": "email" },
                          model: {
                            value: _vm.student.type_of_communication,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.student,
                                "type_of_communication",
                                $$v
                              )
                            },
                            expression: "student.type_of_communication",
                          },
                        },
                        [_vm._v("E-mail")]
                      ),
                      _c(
                        "vs-checkbox",
                        {
                          staticClass: "mr-4 mb-2",
                          attrs: { "vs-value": "sms" },
                          model: {
                            value: _vm.student.type_of_communication,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.student,
                                "type_of_communication",
                                $$v
                              )
                            },
                            expression: "student.type_of_communication",
                          },
                        },
                        [_vm._v("SMS")]
                      ),
                      _c(
                        "vs-checkbox",
                        {
                          staticClass: "mb-2",
                          attrs: { "vs-value": "whatsapp" },
                          model: {
                            value: _vm.student.type_of_communication,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.student,
                                "type_of_communication",
                                $$v
                              )
                            },
                            expression: "student.type_of_communication",
                          },
                        },
                        [_vm._v("Whatsapp")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3_ w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: {
                    label: "Facebook",
                    "icon-pack": "feather",
                    icon: "icon-facebook",
                    "icon-no-border": "",
                  },
                  model: {
                    value: _vm.student.facebook,
                    callback: function ($$v) {
                      _vm.$set(_vm.student, "facebook", $$v)
                    },
                    expression: "student.facebook",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3_ w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: {
                    label: "Instagram",
                    "icon-pack": "feather",
                    icon: "icon-instagram",
                    "icon-no-border": "",
                  },
                  model: {
                    value: _vm.student.instagram,
                    callback: function ($$v) {
                      _vm.$set(_vm.student, "instagram", $$v)
                    },
                    expression: "student.instagram",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3_ w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: {
                    label: "Outra Mídia Social",
                    "icon-pack": "feather",
                    icon: "icon-share-2",
                    "icon-no-border": "",
                  },
                  model: {
                    value: _vm.student.other_social_media,
                    callback: function ($$v) {
                      _vm.$set(_vm.student, "other_social_media", $$v)
                    },
                    expression: "student.other_social_media",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "InfoIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Outros"),
              ]),
            ],
            1
          ),
          _c("hr"),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-2/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Serviço Contratado"),
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: "",
                    reduce: (option) => option.value,
                    options: _vm.servicePackages,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  on: { input: _vm.changeLastService },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.student.intended_services,
                    callback: function ($$v) {
                      _vm.$set(_vm.student, "intended_services", $$v)
                    },
                    expression: "student.intended_services",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Cat. Desejada"),
                ]),
                _c("v-select", {
                  attrs: {
                    options: _vm.categoryCNHOptions,
                    reduce: (option) => option.value,
                    placeholder: "Selecione",
                    clearable: true,
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.student.intended_category_cnh,
                    callback: function ($$v) {
                      _vm.$set(_vm.student, "intended_category_cnh", $$v)
                    },
                    expression: "student.intended_category_cnh",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "date_format:yyyy-MM-dd",
                      expression: "'date_format:yyyy-MM-dd'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    type: "date",
                    label: "Emissão de LADV",
                    "data-vv-as": "Emissão de LADV",
                    name: "ladv_emission",
                  },
                  model: {
                    value: _vm.student.ladv_emission,
                    callback: function ($$v) {
                      _vm.$set(_vm.student, "ladv_emission", $$v)
                    },
                    expression: "student.ladv_emission",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("ladv_emission"),
                        expression: "errors.has('ladv_emission')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Formato incorreto")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "RENACH" },
                  model: {
                    value: _vm.student.renach_form_number,
                    callback: function ($$v) {
                      _vm.$set(_vm.student, "renach_form_number", $$v)
                    },
                    expression: "student.renach_form_number",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "date_format:yyyy-MM-dd",
                      expression: "'date_format:yyyy-MM-dd'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    type: "date",
                    label: "Abertura RENACH",
                    "data-vv-as": "Data Abertura RENACH",
                    name: "opening_date_renach_form_number",
                  },
                  model: {
                    value: _vm.student.opening_date_renach_form_number,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.student,
                        "opening_date_renach_form_number",
                        $$v
                      )
                    },
                    expression: "student.opening_date_renach_form_number",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has(
                          "opening_date_renach_form_number"
                        ),
                        expression:
                          "errors.has('opening_date_renach_form_number')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Formato incorreto")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("CNH Impressa|Digital"),
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: true,
                    reduce: (option) => option.value,
                    options: _vm.typeCNHOptions,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.student.cnh_printed_digital,
                    callback: function ($$v) {
                      _vm.$set(_vm.student, "cnh_printed_digital", $$v)
                    },
                    expression: "student.cnh_printed_digital",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-2/5 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "Campo Extra" },
                  model: {
                    value: _vm.student.extra_field,
                    callback: function ($$v) {
                      _vm.$set(_vm.student, "extra_field", $$v)
                    },
                    expression: "student.extra_field",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Grupos"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (status) => status.value,
                    clearable: "",
                    multiple: "",
                    options: _vm.groups,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.student.groups,
                    callback: function ($$v) {
                      _vm.$set(_vm.student, "groups", $$v)
                    },
                    expression: "student.groups",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-2",
                      attrs: { disabled: !_vm.validateForm || _vm.doubleCpf },
                      on: { click: _vm.save },
                    },
                    [_vm._v("Salvar Alterações")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4 mt-2",
                      attrs: { type: "border", color: "secondary" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("Cancelar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }